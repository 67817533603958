import * as React from 'react'
import Layout from '../components/layout/index'

const NotFound = () => {
    return (
        <Layout className="not-found">
            <div style={{ paddingTop: '100px', textAlign: 'center', minHeight: '50vh' }}>
                <h1>404 PAGE NOT FOUNT</h1>
            </div>
        </Layout>
    )
}

export default NotFound
